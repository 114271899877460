

@media (min-width: 1200px) {
    .navbar-distributed {
      font-size: 20px;
    }
    #Mittelstand-Logo {
      height: 50px;
    }
  }
  
  @media (max-width: 1200px) {
    .navbar-distributed {
      font-size: 18px;
    }
    #Mittelstand-Logo {
      height: 40px;
    }
  }
  
  @media (max-width: 992px) {
    .navbar-distributed {
      font-size: 15px;
    }
    #Mittelstand-Logo {
      height: 35px;
    }
  }
  
  @media (max-width: 576px) {
    .navbar-distributed {
      font-size: 13px;
    }
    #Mittelstand-Logo {
      height: 30px;
    }
  }
  
  .navbar-distributed {
    background-color: #ededed;
    width: 100%;
  }
  
  .container {
    max-width: 100%;
  }
  
  #navbar-link {
    margin: 10px;
    color: #333;
  }
  
  #navbar-link:hover {
    color: darkblue;
  }
  
  .guide-label {
    margin-left: auto;
    margin-right: 15px;
    font-size: 25px;
    color: grey;
  }
  
  @media only screen and (max-width: 1006px) {
    .guide-label {
      display: none;
    }
  }
  