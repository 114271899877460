@media (min-width: 1200px) {
  .footer-distributed {
    font-size: 20px;
  }
  .copyright {
    font-size: 15px;
  }
  #footer-logo {
    height: 110px;
    padding: 4px;
  }
}

@media (max-width: 1200px) {
  .footer-distributed {
    font-size: 18px;
  }
  .copyright {
    font-size: 14px;
  }
  #footer-logo {
    height: 110px;
    padding: 3px;
  }
}

@media (max-width: 992px) {
  .footer-distributed {
    font-size: 15px;
  }
  .copyright {
    font-size: 13px;
  }
  #footer-logo {
    height: 100px;
    padding: 2px;
  }
}

@media (max-width: 576px) {
  .footer-distributed {
    font-size: 13px;
  }
  .copyright {
    font-size: 11px;
  }
  #footer-logo {
    height: 90px;
    padding: 1px;
    padding-left: 0;
    margin-left: 0px;
  }
}

.footer-distributed {
  background-color: #ededed;
  width: 100%;
  padding-left: 10px;
}

.link-container {
  max-width: 100%;
  display: inline-block;
}

#footer-link {
  color: #333;
  margin-right: 20px;
  padding: 0;
  display: inline;
}

#footer-link:hover {
  color: darkblue;
}

.copyright {
  color: #828282;
  margin: 0;
  margin-top: 2px;
  margin-left: 0px;
}

.copyright a {
  text-decoration: underline;
  color: #828282;
}
