@import "../base-definitions.scss";

@media (min-width: 1200px) {
    %navbar-distributed-base {
        font-size: $navbar-font-size-xl;
    }

    #Mittelstand-Logo {
        height: 50px;
    }
}
  
@media (max-width: 1200px) {
    %navbar-distributed-base {
        font-size: $navbar-font-size-lg;
    }

    #Mittelstand-Logo {
        height: 40px;
    }
}
  
@media (max-width: 992px) {
    %navbar-distributed-base {
        font-size: $navbar-font-size-md;
    }

    #Mittelstand-Logo {
        height: 35px;
    }
}
  
@media (max-width: 576px) {
    %navbar-distributed-base {
        font-size: $navbar-font-size-sm;
    }

    #Mittelstand-Logo {
        height: 30px;
    }
}

.navbar-distributed {
    @extend %navbar-distributed-base;
    background-color: $navbar-background-color;
    width: 100%;
}
  
.container {
    max-width: 100%;
}
  
#navbar-link {
    margin: 10px;
    color: $navbar-text-color;
}

#navbar-link:hover {
    color: $hover-color;
}

.guide-label {
    margin-left: auto;
    margin-right: 15px;
    font-size: 25px;
    color: grey;
}

@media only screen and (max-width: 1006px) {
    .guide-label {
        display: none;
    }
}
  