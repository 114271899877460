@media (min-width: 1200px) {
  .strategy-text-container {
    width: 40%;
    line-height: 1.6;
    margin-top: 80px;
  }
  #strategy-map-container {
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .strategy-text-container {
    width: 40%;
    line-height: 1.5;
    margin-top: 80px;
  }
  #strategy-map-container {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .strategy-text-container {
    width: 100%;
    line-height: 1.4;
    margin-top: 67px;
  }
  #strategy-map-container {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .strategy-text-container {
    width: 100%;
    line-height: 1.4;
    margin-top: 60px;
  }
  #strategy-map-container {
    width: 100%;
  }
}

.strategy-text-container {
  float: left;
  color: #282c34;
  margin-left: 3%;
  padding-right: 3%;
}

#strategy-map-container {
  margin-top: 2%;
  margin-right: 1%;
  float: left;
  top: auto;
  bottom: auto;
  margin-left: 3%;
}
