@media (min-width: 1200px) {
  #image {
    width: 165px;
  }
  #name {
    font-size: 16.5px;
  }
}

@media (max-width: 1200px) {
  #image {
    width: 150px;
  }
  #name {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  #image {
    width: 140px;
  }
  #name {
    font-size: 14.45px;
  }
}

@media (max-width: 576px) {
  #image {
    width: 131px;
  }
  #name {
    font-size: 13.5px;
  }
}

#image {
  float: left;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

#name {
  font-weight: bold;
}
