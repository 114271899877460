@import "../../Components/base-definitions.scss";

@media (min-width: 1200px) {
    %image-base {
        width: 165px;
    }

    %name-base {
        font-size: $heading-font-size-xl*0.75;
    }
}

@media (max-width: 1200px) {
    %image-base {
        width: 150px;
    }

    %name-base {
        font-size: $heading-font-size-lg*0.75;  
    }
}

@media (max-width: 992px) {
    %image-base {
        width: 140px;
    }

    %name-base {
        font-size: $heading-font-size-md*0.85;  
    }
}

@media (max-width: 576px) {
    %image-base {
        width: 131px;
    }

    %name-base {
        font-size: $heading-font-size-sm*0.9;  
    }
}

#image {
    @extend %image-base;
    float: left;
    padding: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

#name {
    @extend %name-base;
    font-weight: bold;
}