@import "../../Components/base-definitions.scss";

@media (min-width: 1200px) {
    %text-container-base {
        width: 45%;
        margin-left: 3%;
        margin-top: 3%;
    }

    %image-container-base {
        width: 30%;
        margin-left: 8%;
    }
}
  
@media (max-width: 1200px) {
    %text-container-base {
        width: 45%;
        margin-left: 3%;
        margin-top: 3%;
    }

    %image-container-base {
        width: 30%;
        margin-left: 8%;
    }
}
  
@media (max-width: 992px) {
    %text-container-base {
        width: 100%;
    }

    %image-container-base {
        width: 100%;
        margin-left: 0;
    }
}
  
@media (max-width: 576px) {
    %text-container-base {
        width: 100%;
    }

    %image-container-base {
        width: 100%;
        margin-left: 0;
    }
}

.text-container-partners {
    @extend %text-container-base;
    float: left;
    color: $text-color;
}
  
.image-container-partners {
    @extend %image-container-base;
    margin-top: 0px;
    margin-right: 1%;
    float: left;
    top:auto;
    bottom:auto;
    padding: 5px;
}

.partner-image {
    width: 100%;
}