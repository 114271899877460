
@media (min-width: 1200px) {
  .heading {
    font-size: 33px;
  }
  .text-container {
    width: 45%;
    line-height: 1.6;
    margin-top: 60px;
    padding-top: 15px;
  }
  #map-container {
    width: 50%;
  }
  .hover-text {
    padding: 8px;
  }
}

@media (max-width: 1200px) {
  .heading {
    font-size: 30px;
  }
  .text-container {
    width: 45%;
    line-height: 1.5;
    margin-top: 60px;
    padding-top: 10px;
  }
  #map-container {
    width: 50%;
  }
  .hover-text {
    padding: 7px;
  }
}

@media (max-width: 992px) {
  .heading {
    font-size: 25.5px;
  }
  .text-container {
    width: 100%;
    line-height: 1.4;
    margin-top: 67px;
  }
  #map-container {
    width: 100%;
  }
  .hover-text {
    width: 60%;
    padding: 6px;
  }
  .switch-button-container {
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .text-container {
    width: 100%;
    line-height: 1.4;
    margin-top: 10px;
  }
  #map-container {
    width: 100%;
  }
  .hover-text {
    width: 60%;
    padding: 5px;
  }
  .switch-button-container {
    margin-top: 10px;
  }
}

.text-container {
  float: left;
  color: #282c34;
  /* border-top: 2px solid gray; */
}

#map-container {
  margin-top: 0px;
  margin-right: 1%;
  float: left;
  top: auto;
  bottom: auto;
}

.switch-button-container {
  padding-top: 0px;
  position: absolute;
  color: #282c34;
}

.heading {
  font-weight: bold;
  overflow-wrap: break-word;
  margin-bottom: 0.65vh;
}

.hover-text {
  display: none;
  position: absolute;
  right: 50px;
  background-color: #e6e6e6;
  border: 2px solid black;
  border-radius: 5px;
}

.question-logo:hover + .hover-text {
  display: block;
}
