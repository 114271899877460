@import "../../Components/base-definitions.scss";

@media (min-width: 1200px) {
    %heading-base {
        font-size: $heading-font-size-xl*1.5;
    }

    .ant-radio-wrapper {
        font-size: $standart-font-size-xl;
        padding: 2px;
    }

    %text-container-base {
        width: 45%;
        line-height: 1.6;
        margin-top: 95px;
        padding-top: 15px;
    }

    %map-container-base {
        width: 45%;
    }

    %hover-text-base {
        padding: 8px;    
    }

    %radio-button-base {
        margin-top: 19px;
    }
}
  
@media (max-width: 1200px) {
    %heading-base {
        font-size: $heading-font-size-lg*1.5;
    }

    .ant-radio-wrapper {
        font-size: $standart-font-size-lg;
        padding: 2px;
    }

    %text-container-base {
        width: 45%;
        line-height: 1.5;
        margin-top: 87px;
        padding-top: 10px;
    }

    %map-container-base {
        width: 45%;
    }

    %hover-text-base {
        padding: 7px;
    }

    %radio-button-base {
        margin-top: 17px;
    }
}
  
@media (max-width: 992px) {
    %heading-base {
        font-size: $heading-font-size-md*1.5;
    }

    .ant-radio-wrapper {
        font-size: $standart-font-size-md;
    }

    %text-container-base {
        width: 100%;
        line-height: 1.4;
        margin-top: 67px;
    }

    %map-container-base {
        width: 100%;
    }

    %hover-text-base {
        width: 60%;
        padding: 6px;    
    }

    %radio-button-base {
        margin-top: 10px;
    }
}
  
@media (max-width: 576px) {
    %navbar-distributed-base {
        font-size: $heading-font-size-sm*1.5;
    }

    .ant-radio-wrapper {
        font-size: $standart-font-size-sm;
    }

    %text-container-base {
        width: 100%;
        line-height: 1.4;
        margin-top: 60px;
    }

    %map-container-base {
        width: 100%;
    }

    %hover-text-base {
        width: 60%;
        padding: 5px;
    }

    %radio-button-base {
        margin-top: 10px;
    }
}

.text-container {
    @extend %text-container-base;
    float: left;
    color: $text-color;
    border-top: 2px solid gray;
}
  
#map-container {
    @extend %map-container-base;
    margin-top: 0px;
    margin-right: 1%;
    float: left;
    top:auto;
    bottom:auto;
}

.radio-button-container {
    @extend %radio-button-base;
    padding-top: 0px;
    position: absolute;
    color: $text-color;
}

.heading {
    @extend %heading-base;
    font-weight: bold;
    overflow-wrap: break-word;
    margin-bottom: 0.65vh;
}
  
.radio-button:hover {
    color: $hover-color;
}
  
.question-logo {
    width: 25px;
    right: 20px;
    position: absolute;
    opacity: 0.8;
}
  
.hover-text {
    @extend %hover-text-base;
    display: none;
    position: absolute;
    right: 50px;
    background-color: rgb(230,230,230);
    border: 2px solid black;
    border-radius: 5px;
} 
  
.question-logo:hover + .hover-text {
    display: block;
}