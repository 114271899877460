@import "../base-definitions.scss";

@media (min-width: 1200px) {
  %body-base {
    font-size: $standart-font-size-xl;
  }

  %page-h1-base {
    font-size: $heading-font-size-xl;
  } 
}

@media (max-width: 1200px) {
  %body-base {
    font-size: $standart-font-size-lg;
  }

  %page-h1-base {
    font-size: $heading-font-size-lg;
  } 
}

@media (max-width: 992px) {
  %body-base {
    font-size: $standart-font-size-md;
  }

  %page-h1-base {
    font-size: $heading-font-size-md;
  } 
}

@media (max-width: 576px) {
  %body-base {
    font-size: $standart-font-size-sm;
  }

  %page-h1-base {
    font-size: $heading-font-size-sm;
  } 
}

body {
  @extend %body-base;
  background-color: $app-background-color;
  color: $text-color;
  font-family: $standart-font-family;
  overflow-y: scroll;
}

.Page h1 {
  @extend %page-h1-base;
  color: $header-color;
  font-weight: bold;
} 

.Page {
  display: inline-block;
  padding-top: 1.75vh;
  padding-bottom: 1.75vh;
  margin-left: 3vw;
  margin-right: 3vw;
  width: 90%;
}
